import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import useServiceHook from "./useServicehook";
import ServiceDetailsModal from "./serviceDetailModal";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import renderStars from "./RatingStar";
import { Tab, Tabs } from "@material-ui/core";

const UserServices = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState([]);
  const [selectedTab, setSelectedTab] = useState("pending");
  const { listUserServices, serviceList } = useServiceHook();

  useEffect(() => {
    if (selectedTab) {
      if (selectedTab === "approved") {
        listUserServices(userId, selectedTab, "unblocked");
      } else if (selectedTab === "blocked") {
        listUserServices(userId, "", selectedTab);
      } else {
        listUserServices(userId, selectedTab);
      }
    }
  }, [selectedTab]);
  console.log(serviceList, "serviceList");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "6px",
        }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Pending" value="pending" />
          <Tab label="Approved" value="approved" />
          <Tab label="Rejected" value="rejected" />
          <Tab label="Blocked" value="blocked" />
        </Tabs>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
        gap={2}
      >
        {serviceList?.length > 0 &&
          serviceList?.map((service, index) => (
            <Card
              key={index}
              sx={{
                // //   width: 300,
                // width: "calc(33.33% - 16px)",
                width: "100%",
                maxWidth: 300,
                borderRadius: 2,
                boxShadow: 3,
                padding: 1,
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "transform 0.2s",
                "&:hover": { transform: "scale(1.05)" },
              }}
              onClick={() => {
                setOpen(true);
                setSelectedService(service);
              }}
            >
              {/* Product Image */}
              <Box
                component="img"
                src={service?.upload?.[0]}
                alt="Acrylic Yarn"
                sx={{
                  width: 110,
                  height: 120,
                  borderRadius: 2,
                  objectFit: "cover",
                }}
              />

              {/* Product Details */}
              <CardContent sx={{ padding: "8px 0px", flex: 1 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {service?.serviceName}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  ₹{service?.totalAmount}{" "}
                  <Typography variant="caption" color="textSecondary">
                    (
                    {service?.advancePayment
                      ? "Advance Payment"
                      : "Full Payment"}
                    )
                  </Typography>
                </Typography>

                {/* Rating Section */}
                {/* <Box display="flex" alignItems="center">
                {[...Array(4)].map((_, i) => (
                  <StarIcon key={i} fontSize="small" color="warning" />
                ))}
                <StarIcon fontSize="small" color="disabled" />
                <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                  4.1 ({service?.totalReviews})
                </Typography>
              </Box> */}
                <Box display="flex" alignItems="center">
                  {renderStars(service?.averageRating)}
                  <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                    {service?.averageRating?.toFixed(1)} (
                    {service?.totalReviews})
                  </Typography>
                </Box>

                {/* Product Description */}
                <Typography variant="body2" color="textSecondary" mt={1}>
                  {service?.serviceDetails?.length > 30
                    ? `${service?.serviceDetails.slice(0, 30)}... `
                    : service?.serviceDetails}
                  {service?.serviceDetails?.length > 100 && (
                    <span
                      style={{
                        color: "orangered",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      Read More
                    </span>
                  )}
                </Typography>
              </CardContent>
            </Card>
          ))}
      </Box>
      <ServiceDetailsModal
        open={open}
        onClose={() => setOpen(false)}
        selectedService={selectedService}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(UserServices)
);
// export default UserServices;
