import React from "react";
import { Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const renderStars = (rating = 0) => {
  const totalStars = 5;
  const validRating = isNaN(rating) || rating < 0 ? 0 : rating; // Ensure valid rating
  const roundedRating = Math.round(validRating * 2) / 2; // Round to nearest 0.5
  const fullStars = Math.floor(roundedRating);
  const hasHalfStar = roundedRating % 1 !== 0;
  const emptyStars = Math.max(
    totalStars - fullStars - (hasHalfStar ? 1 : 0),
    0
  ); // Prevent negative values

  return (
    <Box display="flex" alignItems="center">
      {[...Array(fullStars)].map((_, i) => (
        <StarIcon key={i} fontSize="small" color="warning" />
      ))}
      {hasHalfStar && <StarHalfIcon fontSize="small" color="warning" />}
      {[...Array(emptyStars)].map((_, i) => (
        <StarBorderIcon key={i} fontSize="small" color="disabled" />
      ))}
    </Box>
  );
};

export default renderStars;
