import React, { useEffect, useRef, useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  TextareaAutosize,
  Input,
  Radio,
  RadioGroup,
  FormControl,
  IconButton,
} from "@material-ui/core";

import APIRequest from "../../../../../utils/APIRequest.js";
import FormValidation from "../../../../../utils/FormValidation.js";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL.js";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../../utils/SnackbarUtils.js";
import { Stack } from "@mui/material";
import { Chip } from "@mui/material";
import landingImg from "../../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
// import ImageUpload from "./ImageUpload";
import Toaster from "../../../../../utils/Toaster.js";
import isEmpty from "../../../../../utils/isEmpty.js";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagesUpload from "./ImagesUpload.jsx";
import MicIcon from "@material-ui/icons/Mic";
import LocalStorage from "../../../../../config/LocalStorage.js";
import { CheckValidation } from "../../../../../utils/Validator.js";
// import WaveSurfer from "wavesurfer.js";
import {
  Add16Regular,
  Delete24Filled,
  Delete24Regular,
} from "@fluentui/react-icons";
import { DateConvert } from "../../../../../utils/DataConvert.js";
import { Button } from "@fluentui/react";
import LogMessage from "./LogMessages.jsx";
import { WhatsApp } from "@material-ui/icons";
import LogMessages from "../../../../../utils/LogMessages.js";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog.js";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  approveButton: {
    color: "white",
    backgroundColor: "red",
    boxShadow: "0 2px 4px red",
  },
  rejectButton: {
    color: "black",
    backgroundColor: "yellow",
    boxShadow: "0 2px 4px red",
  },
  approve: {
    color: "green",
    borderColor: "green",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "green",
    },
  },
  reject: {
    color: "red",
    borderColor: "red",
    background: "#FFECD3",
    padding: "10px",
    borderRadius: "10px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FFECD3",
      color: "red",
    },
  },
  boxHeader: {
    height: "48px",
    backgroundColor: "#EEF3FF",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "12px",
  },
  boxContainer: {
    boxShadow: "0px 8px 18px 0px #00000014",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    marginBottom: "20px",
    minWidth: "270px",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const serviceDetails = {
  servicePublichTag: "",
  serviceDetails: "",
  serviceName: "",
  upload: [],
  totalAmount: "",
  quanitylimit: "",
  amount: "",
  approvedstatus: "",
  name: "",
  mobileNo: "",
  conditions: [],
  isDeliveryAvailable: "",
  advancePayment: null,
  fullPayment: null,
};

function DetailServices(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(serviceDetails);
  const [selectedButton, setSelectedButton] = useState("");
  const [createdFor, setCreatedFor] = useState("");
  const userDetails = LocalStorage.userDetails;
  const { listLogMessages, listLogs, createLogMessage } = LogMessages();
  const [isOpen, setIsOpen] = useState(false);
  const disabledData = props.formDialog.divType === "view";
  const [rejectedReason, setRejectedReason] = useState("");

  useEffect(() => {
    if (selectedRowId) {
      listLogMessages("members", selectedRowId);
    }
  }, [selectedRowId]);

  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (props?.formDialog?.functionName === "reset") {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(JSON.parse(JSON.stringify(serviceDetails)));
  };

  const getEditable = () => {
    if (
      (props.rowDetails?._id || selectedRowId) &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getServicesDetails,
        JSON.stringify({ serviceId: props.rowDetails?._id || selectedRowId })
        //  +
        //   "?recordId=" +
        //   `${props.rowDetails?._id}`
      ).then((response) => {
        // console.log(response, "response");
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let serviceDetails = response?.data?.result[0];
            console.log(serviceDetails, "postResult");
            setUserForm({
              ...userForm,
              servicePublichTag: serviceDetails?.servicePublichTag,
              serviceDetails: serviceDetails?.serviceDetails,
              serviceName: serviceDetails?.serviceName,
              upload: serviceDetails?.upload,
              totalAmount: serviceDetails?.totalAmount,
              quanitylimit: serviceDetails?.quanitylimit,
              amount: serviceDetails?.amount,
              approvedstatus: serviceDetails?.approvedstatus,
              name: serviceDetails?.name,
              mobileNo: serviceDetails?.mobileNo,
              // images: postResult?.images,
              isDeliveryAvailable: serviceDetails?.isDeliveryAvailable,
              advancePayment: serviceDetails?.advancePayment,
              fullPayment: serviceDetails?.fullPayment,
              conditions: serviceDetails?.conditions,
              blockReason: serviceDetails?.blockReason,
            });
            setCreatedFor(serviceDetails?.userId?._id);
            setSelectedRowId(serviceDetails._id);
          }
        }
      });
    }
  };

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.updateServices;
      userForm["recordId"] = selectedRowId;
      method = "POST";
    }

    let formCopy = { ...userForm };
    // delete formCopy.attachment;
    delete formCopy.range;
    delete formCopy.fragmentedAddress;
    delete formCopy.location;
    delete formCopy.referenceUrl;
    delete formCopy?.startTime;
    delete formCopy?.media;
    delete formCopy?.subject;
    delete formCopy?.tag;
    delete formCopy?.mobileNo;
    delete formCopy?.name;
    delete formCopy?.address;
    delete formCopy?.title;
    delete formCopy?.audio;
    delete formCopy?.tagNo;
    delete formCopy?.sector;
    delete formCopy?.isPermanent;

    const missingFields = CheckValidation(formCopy);
    console.log(missingFields, "misssingFields");
    // if (missingFields.length > 0 || formCopy?.attachment?.length === 0) {
    //   SnackbarUtils.error(
    //     "Please fill all the mandatory fields",
    //     "bottomCenter",
    //     3000
    //   ).then((notification) => {
    //     props.publishNotification(notification);
    //   });
    //   return;
    // }
    if (selectedButton) {
      userForm.sendRequest = selectedButton;
    }
    let userFormCopy = { ...userForm };

    APIRequest.request(method, url, JSON.stringify(userFormCopy)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              userType: "all",
              sortOrder: "false",
              postStatus: "all",
              active: true,
            });
            setUserForm(serviceDetails);
            setSelectedRowId("");
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  const handleDeliverySelection = (event, type) => {
    setUserForm((prevForm) => ({
      ...prevForm,
      isDeliveryAvailable: (() => {
        const { checked } = event.target;
        const currentValue = prevForm?.isDeliveryAvailable || "";

        if (checked) {
          if (currentValue === "" || currentValue === type) return type;
          return "both";
        } else {
          if (currentValue === "both")
            return type === "yourLocation" ? "toCenter" : "yourLocation";
          return "";
        }
      })(),
    }));
  };

  const handleRejectApprove = async () => {
    if (!rejectedReason && selectedButton === "rejected") {
      // Show error if blockReason is required but missing

      SnackbarUtils.error(
        props.t(props.t(`Please provide a reason for rejection`)),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.updateServices,
        JSON.stringify({
          serviceId: selectedRowId,
          approvedstatus: selectedButton,
          ...(selectedButton === "rejected" && {
            rejectedReason: rejectedReason,
          }),
        })
      );

      if (response?.data?.responseCode === 109) {
        getEditable();
        setIsOpen(false);
        SnackbarUtils.sucess(
          props.t("Status Updated Succesfully"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };

  console.log(userForm, "userForm");
  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Stack
          style={{ background: "#FFECD3", height: " 60px", zIndex: "1" }}
          sx={{
            flexDirection: { lg: "row", sm: "row", md: "row" },
            justifyContent: "space-between",
            rowGap: "10px",
          }}
        >
          <Stack style={{ flexDirection: "row", gap: "7px" }}>
            <Stack
              style={{
                paddingBottom: "3px",
              }}
            >
              <Typography
                className="personaldetails"
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "20px",
                  underline: "none",
                  position: "relative",
                  top: "10px",
                  left: "27px",
                }}
              >
                Service Details
              </Typography>
            </Stack>
          </Stack>
          {props.formDialog.divType === "view" && (
            <Stack
              style={{
                flexDirection: "row",
                gap: "7px",
                marginTop: "10px",
                marginRight: "30px",
              }}
            >
              <Button
                className={classes.reject}
                onClick={() => {
                  setSelectedButton("rejected");
                  setIsOpen(true);
                }}
                style={{
                  color: selectedButton === "rejected" ? "white" : "red",
                  backgroundColor: selectedButton === "rejected" ? "red" : null,
                  display: userForm?.approvedstatus === "approved" && "none",
                }}
                disabled={userForm?.approvedstatus !== "pending"}
              >
                Reject
              </Button>
              <Button
                className={classes.approve}
                onClick={() => {
                  setSelectedButton("approved");
                  setIsOpen(true);
                }}
                style={{
                  color: selectedButton === "approved" ? "white" : "green",
                  backgroundColor:
                    selectedButton === "approved" ? "green" : null,
                  display: userForm?.approvedstatus === "rejected" && "none",
                }}
                disabled={userForm?.approvedstatus !== "pending"}
              >
                Approve
              </Button>
            </Stack>
          )}
        </Stack>

        {/* details stack of users */}

        <Stack
          style={{
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            marginTop: "20px",
            position: "relative",
            top: "-24px",
          }}
        >
          <>
            <Stack className={classes.boxContainer}>
              <Box className={classes.boxHeader}>Provider Details</Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "max-content 2fr", // Align labels and values
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <strong>Name :</strong>
                </Typography>
                <Typography>{userForm?.name}</Typography>
                <Typography>
                  <strong>Mobile No :</strong>
                </Typography>
                <Typography>{userForm?.mobileNo}</Typography>
              </Box>
            </Stack>

            <br />
            <Stack className={classes.boxContainer}>
              <Box className={classes.boxHeader}>Service Details</Box>
              <Box style={{ padding: "10px" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "max-content 2fr", // Align labels and values
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <strong>Service Name :</strong>
                  </Typography>
                  <Typography>{userForm?.serviceName}</Typography>

                  <Typography>
                    <strong>Service Details :</strong>
                  </Typography>
                  <Typography>{userForm?.serviceDetails}</Typography>

                  <Typography>
                    <strong>Quantity :</strong>
                  </Typography>
                  <Typography>{userForm?.quanitylimit}</Typography>

                  <Typography>
                    <strong>Payment Mode :</strong>
                  </Typography>
                  <Typography>
                    {userForm?.advancePayment
                      ? "Advance Payment"
                      : userForm?.fullPayment && "Full Payment"}
                  </Typography>

                  <Typography>
                    <strong>Delivery Location :</strong>
                  </Typography>
                  <Typography>{userForm?.isDeliveryAvailable}</Typography>

                  <Typography>
                    <strong>Amount :</strong>
                  </Typography>
                  <Typography>{userForm?.totalAmount}</Typography>

                  <Typography>
                    <strong>Conditions :</strong>
                  </Typography>
                  <Typography>{userForm?.conditions?.join(", ")}</Typography>
                  {userForm?.rejectedReason && (
                    <>
                      <Typography>
                        <strong>Rejected Reason :</strong>
                      </Typography>
                      <Typography>{userForm?.rejectedReason}</Typography>
                    </>
                  )}

                  {userForm?.blockReason && (
                    <>
                      <Typography>
                        <strong>Block Reason :</strong>
                      </Typography>
                      <Typography>{userForm?.blockReason}</Typography>
                    </>
                  )}

                  <Typography>
                    <strong>Images :</strong>
                  </Typography>
                  <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                    {userForm?.upload?.map((val, ind) => (
                      <div
                        onClick={() =>
                          window.open(val, "_blank", "noopener,noreferrer")
                        }
                      >
                        <img
                          key={ind}
                          src={val}
                          alt={`Uploaded file ${ind}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Stack>
            <hr />
            <br />
          </>
        </Stack>
      </Stack>

      <SlideInDialog
        // title={props.t("Delete Post")}
        contentComponent={props.t(
          `Are you sure you want to ${
            selectedButton === "rejected" ? "Reject" : "Approve"
          } ?`
        )}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus="ok"
        slideInDialog={isOpen}
        setSlideInDialog={setIsOpen}
        handleDeleteRequest={handleRejectApprove}
        enquires={true}
        serviceRequest={true}
        rejected={selectedButton === "rejected"}
        blockReason={rejectedReason}
        setBlockReason={setRejectedReason}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(DetailServices)
);
