import { useEffect, useState } from "react";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import { getMonthName } from "../utils/util";

// this is api calls happen

const constantColors = [
  "#A0A8FF",
  "#4F5CE7",
  "#707BF7",
  "#4f5ce7",
  "#6673f3",
  "#8690f3",
  "#a8b0fa",
]; // colors for the pie charts

const useServices = ({ graphFiltersData, pieChartFiltersData }) => {
  const [statisticsData, setStatisticsData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);

  const [statesList, setStatesList] = useState([]);

  useEffect(() => {
    handleGetCountDashboard();
    getStates();
  }, []);
  useEffect(() => {
    getPieChartData();
  }, [JSON.stringify(pieChartFiltersData)]);
  useEffect(() => {
    getGraphData();
  }, [JSON.stringify(graphFiltersData)]);

  const handleGetCountDashboard = async () => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.getDashBoardCounts,
      ""
    );
    if (response?.data?.responseCode === 109) {
      // setStatisticsData(response?.data?.data);
      handleGetCountInfo(response?.data?.data);
    }
  };

  const getContentData = (dataArray, key) => {
    console.log(dataArray, "dataArray");
    return dataArray.map((data, i) => ({
      name: key === "userType" ? data[key]?.join(" & ") : data[key],
      count: data?.count,
    }));
  };

  const structureCountInfoData = (countData, infoData) => {
    const CountDataResult = countData.map((data, i) => {
      if (data?.type === "userStats") {
        return {
          ...data,
          headData: {
            headingName: "No. of Users",
            headingCount: data?.Counts,
          },
          // contentData: getContentData(infoData?.user, "userType"),
          contentData: getContentData(
            [...(infoData?.user || []), ...(infoData?.adminCount || [])],
            "userType"
          ),
        };
      } else if (data?.type === "subscriptionMethodCounts") {
        return {
          ...data,
          headData: {
            headingName: "No. of Subscriptions",
            headingCount: data?.Counts,
          },
          contentData: getContentData(infoData?.typeOfSubscription, "typeName"),
        };
      } else if (data?.type === "postTypeCounts") {
        return {
          ...data,
          headData: {
            headingName: "No. of Posts",
            headingCount: data?.Counts,
          },
          contentData: getContentData(infoData?.postType, "postType"),
        };
      } else if (data?.type === "loomMethodCounts") {
        return {
          ...data,
          headData: {
            headingName: "No. of Looms",
            headingCount: data?.Counts,
          },
          contentData: getContentData(infoData?.loomTypeCounts, "loomType"),
        };
      } else if (data?.type === "serviceMethodCounts") {
        return {
          ...data,
          headData: {
            headingName: "No. of Services",
            headingCount: data?.Counts,
          },
        };
      }
    });

    console.log(CountDataResult, "CountDataResult");
    setStatisticsData(CountDataResult);
  };

  const handleGetCountInfo = async (countData) => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.getCountInfo,
      ""
    );
    if (response?.data?.responseCode === 109) {
      structureCountInfoData(countData, response?.data);
    }
  };

  const structureGraphData = (timeType, apiData) => {
    if (timeType === "month" || timeType === "week") {
      const data = apiData?.map((curr) => {
        const value =
          timeType === "month"
            ? getMonthName(curr?.month)
            : `Week ${curr?.week} , ${getMonthName(curr?.month)}`;
        return {
          ...curr,
          [timeType]: value,
        };
      });
      setGraphData(data);
    } else {
      setGraphData(apiData);
    }
  };

  const structurePieChartData = (pieChartResult) => {
    let structuredData;
    if (pieChartFiltersData?.moduleType === "serviceOrder") {
      structuredData = pieChartResult?.map((data, i) => ({
        name: "Services",
        value: Math.round(data?.totalRevenue),
        fill: constantColors[i],
      }));
    }

    if (pieChartFiltersData?.moduleType === "looms") {
      structuredData = pieChartResult?.map((data, i) => ({
        name: data?.loomType || "Others",
        value: Math.round(data?.totalRevenue),
        fill: constantColors[i],
      }));
    }

    if (pieChartFiltersData?.moduleType === "subscription") {
      structuredData = pieChartResult?.map((data, i) => ({
        name: data?.planeName,
        value: Math.round(data?.totalRevenue),
        fill: constantColors[i],
      }));
    }

    setPieChartData(structuredData);
  };

  const getGraphData = async () => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.getGraphData,
      JSON.stringify({ ...graphFiltersData })
    );
    if (response?.data?.responseCode === 109) {
      structureGraphData(graphFiltersData?.dateType, response?.data?.result);
    }
  };

  const getPieChartData = async () => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.getPieChartData,
      JSON.stringify({ ...pieChartFiltersData })
    );
    if (response?.data?.responseCode === 109) {
      structurePieChartData(response?.data?.result);
    }
  };

  const getStates = async (state = "") => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.getStates,
      JSON.stringify({
        state,
      })
    );
    if (response?.data?.responseCode === 109) {
      setStatesList(response?.data?.result);
    }
  };

  return {
    statisticsData,
    setStatisticsData,
    graphData,
    pieChartData,
    getStates,
    statesList,
  };
};

export default useServices;
