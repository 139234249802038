import React from "react";
import { withTranslation } from "react-i18next";
import { Tooltip, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import WindowSizeListener from "../../utils/WindowSizeListener";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  Edit24Filled,
  Delete24Filled,
  Eye24Filled,
} from "@fluentui/react-icons";
import { Block, Chat, Outbound } from "@mui/icons-material";

const ButtonList = (props) => {
  return props.buttonList.map((buttonDetails, buttonIndex) => {
    if (!buttonDetails.disable) {
      switch (buttonDetails.button) {
        case "add":
          return (
            <Tooltip title={props.translator("dataTable.add")}>
              <IconButton aria-label="add" onClick={() => props.tableAdd()}>
                <AddIcon
                  variant="contained"
                  style={{ color: "#FF8D00", height: "24px", width: "24px" }}
                />
              </IconButton>
            </Tooltip>
          );

        case "edit":
          return (
            <Tooltip title={props.translator("dataTable.edit")}>
              <IconButton aria-label="edit" onClick={() => props.tableEdit()}>
                <Edit24Filled
                  variant="contained"
                  style={{ color: "#FF8D00" }}
                />
              </IconButton>
            </Tooltip>
          );

        case "view":
          return (
            <Tooltip title={props.translator("dataTable.view")}>
              <IconButton aria-label="view" onClick={() => props.tableView()}>
                <Eye24Filled variant="contained" style={{ color: "#FF8D00" }} />
              </IconButton>
            </Tooltip>
          );

        case "assign":
          return (
            <Tooltip title={props.translator("dataTable.assign")}>
              <IconButton
                aria-label="assign"
                onClick={() => props.tableAssign()}
              >
                <GroupAddIcon
                  variant="contained"
                  style={{ color: "#FF8D00" }}
                />
              </IconButton>
            </Tooltip>
          );

        // case 'view':
        // case 'visibility':
        //   return (
        //     <Tooltip title={props.translator('dataTable.view')}>
        //       <IconButton aria-label='view' onClick={() => props.tableView()}>
        //         <VisibilityIcon variant='contained' style={{color:"#FF8D00"}} />
        //       </IconButton>
        //     </Tooltip>
        //   );

        case "delete":
          return (
            <Tooltip title={props.translator("dataTable.delete")}>
              <IconButton
                aria-label="delete"
                onClick={() => props.tableDelete()}
              >
                <Delete24Filled
                  variant="contained"
                  style={{ color: "#FF8D00" }}
                />
              </IconButton>
            </Tooltip>
          );
        case "block":
          return (
            <Tooltip title={props.translator("Block User")}>
              <IconButton
                aria-label="block"
                onClick={() => props.tableUserBlock()}
              >
                <Block variant="contained" style={{ color: "#FF8D00" }} />
              </IconButton>
            </Tooltip>
          );
        case "blockService":
          return (
            <Tooltip title={props.translator("Block Service")}>
              <IconButton
                aria-label="block"
                onClick={() => props.tableServiceBlock()}
              >
                <Block variant="contained" style={{ color: "#FF8D00" }} />
              </IconButton>
            </Tooltip>
          );

        case "live":
          return (
            <Tooltip title={props.translator("Active")}>
              <IconButton
                aria-label="block"
                onClick={() => props.tablePostLive()}
              >
                <Outbound variant="contained" style={{ color: "#FF8D00" }} />
              </IconButton>
            </Tooltip>
          );

        case "upload":
          return (
            <Tooltip title={props.translator("dataTable.upload")}>
              <IconButton
                aria-label="upload"
                onClick={() => props.tableUpload()}
              >
                <CloudUploadIcon
                  variant="contained"
                  style={{ color: "#FF8D00" }}
                />
              </IconButton>
            </Tooltip>
          );

        // case 'approve':
        //   return (
        //     <Tooltip title={props.translator('dataTable.approve')}>
        //       <IconButton
        //         aria-label='approve'
        //         onClick={() => props.tableApprove()}
        //       >
        //         <VerifiedUserIcon variant='contained' />
        //       </IconButton>
        //     </Tooltip>
        //   );

        // case 'reject':
        //   return (
        //     <Tooltip title={props.translator('dataTable.reject')}>
        //       <IconButton
        //         aria-label='reject'
        //         onClick={() => props.tableReject()}
        //       >
        //         <DeleteForeverIcon variant='contained' />
        //       </IconButton>
        //     </Tooltip>
        //   );

        case "changeStatus":
          return (
            <Tooltip title={props.translator("dataTable.changeStatus")}>
              <IconButton
                aria-label="changeStatus"
                onClick={() => props.tableCheckStatus()}
              >
                <SwapHorizontalCircleIcon
                  variant="contained"
                  style={{ color: "#FF8D00" }}
                />
              </IconButton>
            </Tooltip>
          );

        case "notification":
          return (
            <Tooltip title={props.translator("dataTable.notification")}>
              <IconButton
                aria-label="changeStatus"
                onClick={() => props.tableSendNotification()}
              >
                <NotificationsIcon
                  variant="contained"
                  style={{ color: "#FF8D00" }}
                />
              </IconButton>
            </Tooltip>
          );

        case "reset":
          return (
            <Tooltip title={props.translator("dataTable.resetPassword")}>
              <IconButton
                aria-label="changeStatus"
                onClick={() => props.tableResetPassword()}
              >
                <SettingsIcon
                  variant="contained"
                  style={{ color: "#FF8D00" }}
                />
              </IconButton>
            </Tooltip>
          );

        case "chat":
          return (
            <Tooltip title={props.translator("dataTable.chat")}>
              <IconButton aria-label="chat" onClick={() => props.tableChat()}>
                <Chat variant="contained" style={{ color: "#FF8D00" }} />
              </IconButton>
            </Tooltip>
          );

        default:
          return <div></div>;
      }
      // switch (buttonDetails.button) {
      // 	case 'add':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.add')}>
      // 					<IconButton aria-label="add" onClick={() => props.tableAdd()}>
      // 						<AddCircleIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableAdd()}>
      // 					<ListItemIcon>
      // 						<AddCircleIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.add')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'edit':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.edit')}>
      // 					<IconButton aria-label="edit" onClick={() => props.tableEdit()}>
      // 						<EditIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableEdit()}>
      // 					<ListItemIcon>
      // 						<EditIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.edit')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'assign':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.assign')}>
      // 					<IconButton aria-label="assign" onClick={() => props.tableAssign()}>
      // 						<GroupAddIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableAssign()}>
      // 					<ListItemIcon>
      // 						<GroupAddIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.assign')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'view':
      // 	case 'visibility':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.view')}>
      // 					<IconButton aria-label="view" onClick={() => props.tableView()}>
      // 						<VisibilityIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableView()}>
      // 					<ListItemIcon>
      // 						<VisibilityIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.view')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'delete':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.delete')}>
      // 					<IconButton aria-label="delete" onClick={() => props.tableDelete()}>
      // 						<DeleteIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableDelete()}>
      // 					<ListItemIcon>
      // 						<DeleteIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.delete')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'upload':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.upload')}>
      // 					<IconButton aria-label="upload" onClick={() => props.tableUpload()}>
      // 						<CloudUploadIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableUpload()}>
      // 					<ListItemIcon>
      // 						<CloudUploadIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.upload')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'approve':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.approve')}>
      // 					<IconButton aria-label="approve" onClick={() => props.tableApprove()}>
      // 						<VerifiedUserIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableApprove()}>
      // 					<ListItemIcon>
      // 						<VerifiedUserIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.approve')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'reject':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.reject')}>
      // 					<IconButton aria-label="reject" onClick={() => props.tableReject()}>
      // 						<DeleteForeverIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableReject()}>
      // 					<ListItemIcon>
      // 						<DeleteForeverIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.reject')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	case 'changeStatus':
      // 		if (props.width > 960) {
      // 			return (
      // 				<Tooltip title={props.translator('dataTable.changeStatus')}>
      // 					<IconButton aria-label="changeStatus" onClick={() => props.tableCheckStatus()}>
      // 						<SwapHorizontalCircleIcon variant="contained" />
      // 					</IconButton>
      // 				</Tooltip>
      // 			);
      // 		} else {
      // 			return (
      // 				<MenuItem onClick={() => props.tableCheckStatus()}>
      // 					<ListItemIcon>
      // 						<SwapHorizontalCircleIcon fontSize="small" />
      // 					</ListItemIcon>
      // 					<Typography variant="inherit" noWrap>
      // 						{props.translator('dataTable.changeStatus')}
      // 					</Typography>
      // 				</MenuItem>
      // 			);
      // 		}

      // 	default:
      // 		return <div></div>;
      // }
    }
  });
};

function TableButtons(props) {
  const { width, height } = WindowSizeListener();

  return (
    <ButtonList
      width={width}
      height={height}
      buttonList={props.buttonList}
      translator={props.t}
      tableAdd={props.tableAdd}
      tableEdit={props.tableEdit}
      tableAssign={props.tableAssign}
      tableView={props.tableView}
      tableDelete={props.tableDelete}
      tableUpload={props.tableUpload}
      tableApprove={props.tableApprove}
      tableReject={props.tableReject}
      tableCheckStatus={props.tableCheckStatus}
      tableSendNotification={props.tableSendNotification}
      tableResetPassword={props.tableResetPassword}
      tableUserBlock={props.tableUserBlock}
      tableChat={props.tableChat}
      tablePostLive={props.tablePostLive}
      tableServiceBlock={props.tableServiceBlock}
    />
  );
}
export default withTranslation("translations")(TableButtons);
