import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import renderStars from "./RatingStar";
import ExpandableText from "./ExpandText";

const ServiceDetailsModal = ({ open, onClose, selectedService }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!selectedService?.upload || selectedService.upload.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % selectedService.upload.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [selectedService?.upload]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { width: "360px" } }}
      fullWidth
    >
      {/* Header */}
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "48px",
          backgroundColor: "#EEF3FF",
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
        }}
      >
        Service Details
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: 0 }}>
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          {selectedService?.upload?.length > 0 && (
            <>
              <img
                src={selectedService.upload[currentIndex]}
                alt="Service"
                style={{
                  width: "100%",
                  height: "180px",
                  objectFit: "cover",
                  transition: "opacity 0.5s ease-in-out",
                }}
              />
              {/* Dots Indicator */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 8,
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  gap: "5px",
                }}
              >
                {selectedService.upload.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor:
                        currentIndex === index ? "#FF8D00" : "#D3D3D3",
                      transition: "background-color 0.3s ease",
                    }}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>

        {/* Content */}
        <Box sx={{ padding: "16px" }}>
          {/* Title & Price */}
          <Typography variant="h6" fontWeight="bold">
            {selectedService?.serviceName}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {renderStars(selectedService?.averageRating)}
              <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                {selectedService?.averageRating?.toFixed(1)} (
                {selectedService?.totalReviews})
              </Typography>
            </Box>
            <Typography variant="body1" color="#FF8D00" fontWeight="bold">
              ₹{selectedService?.totalAmount}
            </Typography>
          </Box>
          <Typography variant="caption" color="textSecondary">
            (
            {selectedService?.advancePayment
              ? "Advance Payment"
              : "Full Payment"}
            )
          </Typography>

          {/* Description */}

          <ExpandableText
            text={selectedService?.serviceDetails}
            maxChars={100}
          />

          {/* Conditions */}
          <Box
            sx={{
              boxShadow: "0px 8px 18px 0px #00000014",
              borderBottomRightRadius: "8px",
              borderBottomLeftRadius: "8px",
              marginBottom: "20px",
              minWidth: "270px",
            }}
          >
            {/* Header */}
            <Box
              sx={{
                height: "48px",
                backgroundColor: "#EEF3FF",
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
                display: "flex",
                alignItems: "center",
                padding: "12px",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Conditions
              </Typography>
            </Box>

            {/* Conditions List */}
            <Box
              sx={{
                padding: "12px",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {selectedService?.conditions?.map((val, index) => (
                <Typography key={index} variant="body2" display="block">
                  •{val}
                </Typography>
              ))}
            </Box>
          </Box>

          {/* Book Now Button */}
          {/* <Button
            variant="contained"
            color="warning"
            fullWidth
            sx={{ marginTop: 2, borderRadius: 5 }}
          >
            Book Now
          </Button> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ServiceDetailsModal;
