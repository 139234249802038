import React, { useEffect, useRef, useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  Checkbox,
  TextareaAutosize,
  Input,
  Select,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import APIRequest from "../../../../../utils/APIRequest.js";
import FormValidation from "../../../../../utils/FormValidation.js";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL.js";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../../utils/SnackbarUtils.js";
import { Button, Stack } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagesUpload from "./ImagesUpload.jsx";
import MicIcon from "@material-ui/icons/Mic";
import LocalStorage from "../../../../../config/LocalStorage.js";
import { DateConvert } from "../../../../../utils/DataConvert.js";
import LogMessage from "../../Enquiries/Members/LogMessages.jsx";
import ChatInput from "../../Enquiries/Members/ChartInput.jsx";
import { Info } from "@material-ui/icons";
import LogMessages from "../../../../../utils/LogMessages.js";
import PaymentDialog from "./paymentDialog.jsx";
import DateConversion from "../../../../../utils/DateConversion.js";
import { ContentCopy } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  approveButton: {
    color: "white",
    backgroundColor: "red",
    boxShadow: "0 2px 4px red",
  },
  rejectButton: {
    color: "black",
    backgroundColor: "yellow",
    boxShadow: "0 2px 4px red",
  },
  heading: {
    fontWeight: 700,
    color: "#323130",
    // paddingBottom: "2px",
    fontSize: "16px",
    underline: "none",
    padding: "5px",
  },
  stack: {
    padding: "20px",
    background: "white",
    borderRadius: "8px",
    // marginTop: "20px",
    // position: "relative",
    // top: "-24px",
  },
  prefix: {
    color: "#868686",
    fontSize: "14px",
  },
  prefixValue: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 2,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const postDetails = {
  providerDetails: {
    userName: "",
    sector: "",
    category: "",
    MobileNo: "",
    address: "",
    UpiId: "",
    bankAccountNumber: "",
    IFSC: "",
  },
  SeekerDetails: {
    userName: "",
    MobileNo: "",
    deliveryAddress: "",
    deliveryCode: "",
  },
  BookingDetails: {
    bookingId: "",
    bookingDate: "",
    PaymentMode: "",
    paymentType: "",
    Amount: "",
  },
  trackingReport: {
    orderPlaced: "",
    ProviderAcceptance: "",
    delivery: "",
    settlement: "",
    code: "",
    status: "",
  },
  quantityCount: "",
  serviceAmount: "",
  serviceImages: "",
  serviceTitle: "",
  gstServiceAmount: "",
  adminListStatus: "",
  fullPayment: null,
  advancePayment: null,
  transactionId: "",
};

function AddUser(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(postDetails);
  const [categorydata, setCategoryData] = useState([]);
  const [sectorlist, setSectorlist] = useState([]);
  const { listLogMessages, listLogs, createLogMessage } = LogMessages();
  const [isOpen, setIsOpen] = useState(false);
  const userId = LocalStorage?.userDetails?._id;

  useEffect(() => {
    if (selectedRowId) {
      listLogMessages("serviceOrder", selectedRowId);
    }
  }, [selectedRowId]);

  useEffect(() => {
    //changes made here
    // setUserForm(postDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (props?.formDialog?.functionName === "reset") {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(JSON.parse(JSON.stringify(postDetails)));
  };
  const categorylist = () => {
    APIRequest.request("GET", ConfigAPIURL.listcategory, "").then(
      (response) => {
        let userResult = response?.data?.result;
        setCategoryData(userResult);
        // console.log(categorydata, "categorydata");
      }
    );
  };

  useEffect(() => {
    categorylist();
  }, []);
  // console.log(categorydata, "data");

  //getting data for sector
  const sectorListData = () => {
    APIRequest.request("GET", ConfigAPIURL.sectorData, "").then((response) => {
      let userResult = response?.data?.result;
      setSectorlist(userResult);
      // console.log(sectorlist, "sectorData");
    });
  };

  useEffect(() => {
    sectorListData();
  }, []);

  // console.log(sectorlist, "sectorlist");

  const getEditable = () => {
    if (
      // props.rowDetails !== undefined &&
      // props.rowDetails !== null &&
      (props.rowDetails?._id || selectedRowId) &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.serviceDetails,
        JSON.stringify({ recordId: props.rowDetails?._id || selectedRowId })
      ).then((response) => {
        // console.log(response, "response");
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let serviceData = response?.data?.result;
            console.log(serviceData, "postResult");
            setUserForm({
              ...userForm,
              providerDetails: {
                userName: serviceData?.serviceUserDetails?.[0]?.name,
                sector: serviceData?.serviceUserDetails?.[0]?.sector,
                category: serviceData?.serviceUserDetails?.[0]?.category,
                MobileNo: serviceData?.serviceUserDetails?.[0]?.mobileNo,
                address:
                  serviceData?.serviceUserDetails?.[0]?.fragmentedAddress,
                UpiId: serviceData?.serviceUserDetails?.[0]?.upiId?.[0],
                IFSC: serviceData?.serviceUserDetails?.[0]?.ifcNo,
                bankAccountNumber:
                  serviceData?.serviceUserDetails?.[0]?.bankNumber,
              },
              SeekerDetails: {
                userName: serviceData?.createdBy?.name,
                MobileNo: serviceData?.createdBy?.mobileNo,
                deliveryAddress: serviceData?.location?.name,
                deliveryCode: serviceData?.confirmationCode,
              },
              BookingDetails: {
                bookingId: serviceData?.orderTag,
                bookingDate: serviceData?.createdAt,
                PaymentMode:
                  serviceData?.paymentDetails?.[0]?.paymentResponse
                    ?.payment_response?.card_type,
                paymentType: "",
                Amount: serviceData?.serviceAmount,
              },
              trackingReport: {
                orderPlaced: serviceData?.orderStatus,
                ProviderAcceptance: serviceData?.ProviderAcceptance,
                status: serviceData?.ProviderAcceptance,
                delivery: serviceData?.Delivered,
                settlement: serviceData?.PaymentSettlement,
                code: "",
              },
              serviceAmount: serviceData?.serviceDetails?.totalAmount,
              quantityCount: serviceData?.quantityCount,
              serviceTitle: serviceData?.serviceDetails?.serviceName,
              serviceImages: serviceData?.serviceDetails?.upload?.[0],
              gstServiceAmount: serviceData?.serviceDetails?.amount,
              adminListStatus: serviceData?.adminListStatus,
              advancePayment: serviceData?.serviceDetails?.advancePayment,
              fullPayment: serviceData?.serviceDetails?.fullPayment,
              transactionId:
                serviceData?.paymentDetails?.[0]?.paymentInitiated?.txnid,
            });

            setSelectedRowId(serviceData._id);
          }
        }
      });
    }
  };

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        // handleChange();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.postCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.serviceUpdate;
      userForm["recordId"] = selectedRowId;
      method = "POST";
    }

    let formCopy = { ...userForm };
    console.log(userForm, "formUser");
    let userFormCopy = {
      recordId: selectedRowId,
      ...(userForm?.trackingReport?.status !== "Pending" &&
      userForm?.trackingReport?.delivery !== "Delivered"
        ? { ProviderAcceptance: userForm?.trackingReport?.status }
        : {}),
      ...(userForm?.trackingReport?.settlement === "Settlement"
        ? {
            PaymentSettlement: userForm?.trackingReport?.settlement,
            adminListStatus: "Settlement",
          }
        : {}),
    };

    // let userFormCopy = {
    //   recordId: selectedRowId,
    //   ...(userForm?.trackingReport?.status !== "Pending" &&
    //     userForm?.trackingReport?.delivery !== "Delivered" && {
    //       ProviderAcceptance: userForm?.trackingReport?.status,
    //     }),
    //   ...(userForm?.trackingReport?.settlement === "Settlement" && {
    //     PaymentSettlement: userForm?.trackingReport?.settlement,
    //     adminListStatus: "Settlement",
    //   }),
    // };
    APIRequest.request(method, url, JSON.stringify(userFormCopy)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              userType: "all",
              sortOrder: "false",
              active: true,
            });
            setUserForm(postDetails);
            setSelectedRowId("");
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  const handleCopy = (text) => {
    if (text) {
      navigator.clipboard.writeText(text);
      SnackbarUtils.sucess(
        props.t("Copied to clipboard!"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  //function for confirm code
  const handleConfirmationCode = async (code, orderId) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.serviceOrderConfirm,
        JSON.stringify({ code, orderId })
      );

      if (response?.data?.responseCode === 109) {
        console.log("Predictions:", response?.data?.message);
        getEditable();
        SnackbarUtils.sucess(
          props.t("Code verified successfully"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else {
        console.log(response);
        SnackbarUtils.error(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      SnackbarUtils.sucess(
        props.t("something went wrong"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      console.error("Error fetching location:", error.message || error);
    }
  };

  //function for payment
  const handleSettlement = async (amount) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.requestPayment,
        JSON.stringify({
          recordId: selectedRowId,
          amount,
          userId,
          bookingFor: "settlement",
        })
      );

      if (response?.data?.responseCode === 109) {
        console.log("Predictions:", response);
        handlePayment(response?.data?.result);
        // SnackbarUtils.sucess(
        //   props.t(response?.data?.message),
        //   "bottomCenter",
        //   3000
        // ).then((notification) => {
        //   props.publishNotification(notification);
        // });
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };

  const handlePayment = (data, ID) => {
    try {
      console.log(data.data);
      const accessKey = data?.data;
      if (!accessKey) {
        throw new Error("Invalid payment access key");
      }
      if (typeof window.EasebuzzCheckout !== "function") {
        throw new Error(
          "EasebuzzCheckout is not available. Ensure the script is loaded."
        );
      }

      // const easebuzzCheckout = new window.EasebuzzCheckout(accessKey, "test");
      const easebuzzCheckout = new window.EasebuzzCheckout(accessKey, "prod");

      const options = {
        access_key: accessKey,
        payment_mode: "upi",
        onResponse: (response) => {
          console.log("Payment response:", response);
          handlePaymentStatus(response);
          // updateBookingResult(response, ID);
        },
        theme: "#default",
      };
      console.log(options);
      easebuzzCheckout.initiatePayment(options);
    } catch (error) {
      console.error("Error in handlePayment:", error.message || error);
      SnackbarUtils.error(
        "An error occurred during payment initialization",
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const handleFetchPayment = async (amount) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.fetchPayment,
        JSON.stringify({
          recordId: selectedRowId,
          amount,
          paymentId: userForm?.transactionId,
        })
      );

      if (response?.data?.responseCode === 109) {
        console.log("Predictions:", response);
        handleRefundPayment(response?.data?.data?.msg);
        // SnackbarUtils.sucess(
        //   props.t(response?.data?.message),
        //   "bottomCenter",
        //   3000
        // ).then((notification) => {
        //   props.publishNotification(notification);
        // });
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };
  const handleRefundPayment = async (data) => {
    console.log(data[0]?.amount, "data");
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.refundPayment,
        JSON.stringify({
          recordId: selectedRowId,
          amountPaid: data[0]?.amount,
          paymentId: data[0]?.txnid,
          easeBuzzId: data[0]?.easepayid,
        })
      );

      if (response?.data?.responseCode === 109) {
        console.log("Predictions:", response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        getEditable();
        setIsOpen(false);
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };

  const handlePaymentStatus = async (data) => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.paymentResponse,
        JSON.stringify({
          recordId: selectedRowId,
          userId,
          result: data?.status,
          bookingFor: "settlement",
          payment_response: data,
        })
      );

      if (response?.data?.responseCode === 142) {
        console.log("Predictions:", response);
        getEditable();
        setIsOpen(false);
        SnackbarUtils.sucess(
          props.t("Amount settled Successfully"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else if (response?.data?.responseCode === 141) {
        SnackbarUtils.error(
          props.t(response?.data?.result),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      } else {
        console.log(response);
        SnackbarUtils.sucess(
          props.t(response?.data?.message),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error.message || error);
    }
  };

  // const providerAmount = userForm?.BookingDetails?.Amount
  //   ? ((userForm.BookingDetails.Amount - 50) * 0.82).toFixed(2)
  //   : "0.00";
  const providerAmount = userForm?.quantityCount * userForm?.gstServiceAmount;

  const gstAmount = userForm?.gstServiceAmount * 0.18;

  console.log(userForm, "userForm");
  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.marginTop,
        fontFamily: "Lato",
        position: "relative",
        top: "-13px",
      }}
    >
      <Stack style={{ padding: "20px" }}>
        {/* buttons stack */}

        <Grid
          container
          style={{ background: "#FFECD3", height: "46px", zIndex: "1" }}
        >
          <Grid item xs={12} lg={6} md={6}>
            <Typography className={classes.heading}>
              Provider Details
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <Typography className={classes.heading}>Seeker Details</Typography>
          </Grid>
        </Grid>
        <Stack className={classes.stack}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>User Name</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.userName}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Sector</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.sector ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Category</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.category ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Mobile No</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.MobileNo}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Upi Id</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.UpiId ?? "-"}
                  </Typography>
                  {userForm?.providerDetails?.UpiId && (
                    <IconButton
                      size="small"
                      onClick={() => handleCopy(userForm.providerDetails.UpiId)}
                    >
                      <ContentCopy fontSize="small" color="black" />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Account Number
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.bankAccountNumber ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>IFSC Code</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.IFSC ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Address</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.providerDetails?.address ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>User Name</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.SeekerDetails?.userName}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Mobile No</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.SeekerDetails?.MobileNo}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Delivery Code
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Typography className={classes.prefixValue}>
                    {userForm?.SeekerDetails?.deliveryCode ?? "-"}
                  </Typography>
                  {userForm?.SeekerDetails?.deliveryCode && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopy(userForm?.SeekerDetails?.deliveryCode)
                      }
                    >
                      <ContentCopy fontSize="small" color="black" />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Delivery Address
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.SeekerDetails?.deliveryAddress ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        {/* third */}
        <Grid
          container
          style={{ background: "#FFECD3", height: "46px", zIndex: "1" }}
        >
          <Grid item xs={12} lg={6} md={6}>
            <Typography className={classes.heading}>Booking Details</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography className={classes.heading}>Tracking Report</Typography>

            {userForm?.adminListStatus === "seekerCancel" ||
              (userForm?.trackingReport?.ProviderAcceptance === "Rejected" && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#ff8c00",
                    color: "#fff",
                    textTransform: "none",
                    padding: "8px 16px",
                    borderRadius: "4px",
                    minWidth: "80px",
                    height: "40px",
                    "&:hover": {
                      backgroundColor: "#e57700",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#ff8c00",
                      color: "#fff",
                      cursor: "not-allowed",
                    },
                  }}
                  // disabled={
                  //   userForm?.adminListStatus !== "seekerCancel" &&
                  //   userForm?.trackingReport?.ProviderAcceptance !== "Rejected" &&
                  //   (userForm?.trackingReport?.delivery !== "Delivered" ||
                  //     userForm?.trackingReport?.settlement === "Settlement")
                  // }
                  onClick={() => {
                    setIsOpen(true);
                    // handleSettlement(userForm?.BookingDetails?.Amount)
                  }}
                >
                  {/* {userForm?.adminListStatus === "seekerCancel" ||
                  userForm?.trackingReport?.ProviderAcceptance === "Rejected"
                    ? "Refund"
                    : "Settlement"} */}
                  Refund
                </Button>
              ))}
          </Grid>
        </Grid>
        <Stack className={classes.stack}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Booking Id</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.BookingDetails?.bookingId}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Booking Date & Time
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefixValue}>
                    {DateConversion.unixToLocaleDate(
                      userForm?.BookingDetails?.bookingDate
                    )}
                    {/* {userForm?.BookingDetails?.bookingDate} */}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Payment Mode
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.BookingDetails?.PaymentMode}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Payment Type
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefixValue}>
                    {userForm?.fullPayment
                      ? "Full Payment"
                      : userForm?.advancePayment
                      ? "Advance Payment"
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Amount Paid
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "6px",
                  }}
                >
                  <Typography
                    className={classes.prefixValue}
                    style={{ color: "#4F5CE7", fontWeight: 800 }}
                  >
                    ₹{userForm?.BookingDetails?.Amount}
                  </Typography>
                  {/* <IconButton> */}
                  <Tooltip
                    title={
                      <Box>
                        <Typography>
                          Total Amount: ₹{userForm?.BookingDetails?.Amount}
                        </Typography>
                        <hr style={{ width: "100%" }} />
                        <Typography>
                          Provider Amount: {providerAmount}
                        </Typography>
                        <Typography>
                          {" "}
                          Convenience Fee: {userForm?.quantityCount * 50}
                        </Typography>
                        <Typography>
                          GST Amount: ₹{userForm?.quantityCount * gstAmount}
                        </Typography>
                      </Box>
                    }
                    style={{
                      background: "white",
                      boxShadow: "0px 8px 22px 0px #00000012",
                    }}
                    arrow
                    placement="bottom"
                  >
                    <Info
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>

                  {/* </IconButton> */}
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      padding: "12px",
                      maxWidth: "350px",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* Product Image */}
                    <Box
                      component="img"
                      src={userForm?.serviceImages} // Replace with actual image
                      alt="Product"
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />

                    {/* Product Details */}
                    <Box sx={{ flex: 1, marginLeft: "12px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        {userForm?.serviceTitle}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#333",
                          marginTop: "4px",
                        }}
                      >
                        ₹ {userForm?.serviceAmount}
                      </Typography>
                    </Box>

                    {/* Quantity */}
                    <Typography sx={{ fontSize: "14px", color: "#666" }}>
                      Qty : {userForm?.quantityCount}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Order Placed
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    className={classes.prefixValue}
                    style={{ color: "green" }}
                  >
                    Done
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.prefix}>
                    Provider Acceptance
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <Typography
                    className={classes.prefixValue}
                    style={{ color: "#FF8D00" }}
                  >
                    {userForm?.trackingReport?.delivery ?? "-"}
                  </Typography> */}
                  <Select
                    value={
                      userForm?.trackingReport?.status ||
                      userForm?.trackingReport?.ProviderAcceptance
                    }
                    onChange={(e) => {
                      const { value } = e.target;
                      setUserForm((prev) => ({
                        ...prev,
                        trackingReport: {
                          ...prev.trackingReport,
                          status: value,
                        },
                      }));
                    }}
                    style={{
                      color:
                        userForm?.trackingReport?.status === "Approved"
                          ? "green"
                          : userForm?.trackingReport?.status === "Rejected"
                          ? "red"
                          : "#FF8D00",
                    }}
                    disabled={
                      userForm?.adminListStatus === "seekerCancel" ||
                      userForm?.trackingReport?.ProviderAcceptance !== "Pending"
                    }
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Approved">Approve</MenuItem>
                    <MenuItem value="Rejected">Reject</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Delivery</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className={classes.prefixValue}
                    style={{
                      color:
                        userForm?.trackingReport?.delivery === "Delivered"
                          ? "green"
                          : "#FF8D00",
                    }}
                  >
                    {userForm?.trackingReport?.delivery ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 2,
                      backgroundColor: "#fff",
                      padding: "4px 8px",
                      borderRadius: "24px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <TextField
                      variant="standard"
                      placeholder="Enter Code"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: "16px", color: "gray" },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "0px",
                        },
                      }}
                      value={userForm?.trackingReport?.code}
                      onChange={(e) => {
                        const value = e.target.value;
                        setUserForm((prev) => ({
                          ...prev,
                          trackingReport: {
                            ...prev.trackingReport,
                            code: value || "",
                          },
                        }));
                      }}
                      disabled={
                        userForm?.trackingReport?.ProviderAcceptance !==
                          "Approved" ||
                        userForm?.trackingReport?.delivery === "Delivered"
                      }
                    />
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#FF9200",
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "6px 16px",
                        "&:hover": {
                          backgroundColor: "#e68200",
                        },
                      }}
                      onClick={() => {
                        handleConfirmationCode(
                          userForm?.trackingReport?.code,
                          selectedRowId
                        );
                      }}
                      disabled={
                        userForm?.trackingReport?.ProviderAcceptance !==
                          "Approved" ||
                        userForm?.trackingReport?.delivery === "Delivered"
                      }
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prefix}>Settlement</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">:</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <Typography
                    className={classes.prefixValue}
                    style={{
                      color:
                        userForm?.trackingReport?.settlement === "Pending"
                          ? "#FF8D00"
                          : "green",
                    }}
                  >
                    {userForm?.trackingReport?.settlement}
                  </Typography> */}
                  <Select
                    value={userForm?.trackingReport?.settlement ?? ""}
                    onChange={(e) => {
                      const { value } = e.target;
                      setUserForm((prev) => ({
                        ...prev,
                        trackingReport: {
                          ...prev.trackingReport,
                          settlement: value,
                        },
                      }));
                    }}
                    style={{
                      color:
                        userForm?.trackingReport?.settlement === "Settlement"
                          ? "green"
                          : // : userForm?.trackingReport?.settlement ===
                            //   "Rejected"
                            // ? "red"
                            "#FF8D00",
                    }}
                    disabled={
                      userForm?.trackingReport?.delivery !== "Delivered" ||
                      userForm?.trackingReport?.settlement === "Settlement"
                    }
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Settlement">settlement</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>

        {/* fourth */}
        <Grid
          container
          style={{ background: "#FFECD3", height: "46px", zIndex: "1" }}
        >
          <Grid item xs={12} lg={6} md={6}>
            <Typography className={classes.heading}>Logs</Typography>
          </Grid>
        </Grid>
        <Stack className={classes.stack}>
          {listLogs?.length > 0
            ? listLogs?.map((val, ind) => (
                <LogMessage
                  message={val?.sendMessage}
                  userName={val?.createdBy?.name}
                  time={val?.createdAt}
                  isShowLast={listLogs?.length - 1 === ind}
                />
              ))
            : "No logs present"}

          <ChatInput
            createLogMessage={createLogMessage}
            recordId={selectedRowId}
            listLogMessages={listLogMessages}
            moduleType="serviceOrder"
          />
        </Stack>
      </Stack>

      <PaymentDialog
        title={props.t(
          `${
            userForm?.adminListStatus === "seekerCancel" ||
            userForm?.trackingReport?.ProviderAcceptance === "Rejected"
              ? "Seeker Refund"
              : "Provider Settlement"
          }`
        )}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={isOpen}
        setSlideInDialog={setIsOpen}
        amount={providerAmount}
        totalAmount={userForm?.BookingDetails?.Amount}
        gstAmount={gstAmount}
        handlePaymentRequest={
          userForm?.adminListStatus === "seekerCancel" ||
          userForm?.trackingReport?.ProviderAcceptance === "Rejected"
            ? handleFetchPayment //handleRefundPayment
            : handleSettlement
        }
        //  handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AddUser)
);
