import React, { useState } from "react";
import { Typography } from "@mui/material";

const ExpandableText = ({ text, maxChars = 100 }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  const displayText = expanded
    ? text
    : text?.slice(0, maxChars) + (text?.length > maxChars ? "..." : "");

  return (
    <Typography variant="body2" color="textSecondary" mt={1}>
      {displayText}
      {text?.length > maxChars && (
        <span
          style={{ color: "orangered", fontWeight: "bold", cursor: "pointer" }}
          onClick={toggleExpand}
        >
          {expanded ? " Read Less" : " Read More.."}
        </span>
      )}
    </Typography>
  );
};
export default ExpandableText;
