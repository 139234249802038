import { useState } from "react";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";

const useServiceHook = () => {
  const [serviceList, setServiceList] = useState([]);
  const listUserServices = async (recordId, approvedstatus, serviceBlock) => {
    try {
      let url = ConfigAPIURL.userServices;
      let method = "POST";
      const response = await APIRequest.request(
        method,
        url,
        JSON.stringify({ userId: recordId, approvedstatus, serviceBlock })
      );

      if (response && response?.data?.responseCode === 109) {
        setServiceList(response?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    listUserServices,
    serviceList,
  };
};
export default useServiceHook;
