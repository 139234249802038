import React from "react";
import PaymentIcon from "@mui/icons-material/Payment";

import {
  Board24Filled,
  Board24Regular,
  People24Filled,
  People24Regular,
  CommentEdit24Filled,
  CommentEdit24Regular,
  ShareAndroid24Regular,
  ShareAndroid24Filled,
  PeopleMoney24Filled,
  PeopleMoney24Regular,
  PeopleCall24Filled,
  PeopleCall24Regular,
  PeopleCheckmark24Filled,
  PeopleCheckmark24Regular,
  Settings24Filled,
  Settings24Regular,
  Headphones24Regular,
  Headphones24Filled,
  Megaphone24Regular,
  Megaphone24Filled,
  CardUi24Filled,
  CardUi24Regular,
  PeopleProhibited24Filled,
  PeopleProhibited24Regular,
  CollectionsAdd24Regular,
  CollectionsAdd24Filled,
  ClipboardTaskListLtr24Regular,
  ClipboardTaskListLtr24Filled,
  BoardSplit24Regular,
  BoardSplit24Filled,
  DocumentData24Filled,
  DocumentData24Regular,
  ClipboardTextLtr24Regular,
  ClipboardTextLtr24Filled,
  ContentView24Filled,
  ContentView24Regular,
} from "@fluentui/react-icons";

class SideBarMenu {
  static menu = [
    {
      display: "sideNavMenu.dashboard",
      link: "/admin/dashboard",
      linkPage: false,
      onclick: null,
      urlEndWith: "dashboard",
      className: "",
      permissionLevel: "Dashboard",
      filledIcon: <Board24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <Board24Regular style={{ color: "black" }} />,
      eventKey: "dashboard",
      subMenu: [],
    },
    {
      display: "sideNavMenu.user",
      link: "/admin/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "Users",
      filledIcon: <People24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <People24Regular style={{ color: "black" }} />,
      eventKey: "users",
      color: "white",
      subMenu: [],
    },
    {
      display: "sideNavMenu.enquiries",
      link: "/admin/enquiries",
      linkPage: false,
      onclick: null,
      urlEndWith: "enquiries",
      className: "",
      permissionLevel: "Enquiries",
      // icon: <FaEdit style={{ color: "black" }} />,
      filledIcon: <CollectionsAdd24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <CollectionsAdd24Regular style={{ color: "black" }} />,
      eventKey: "enquiries",
      color: "white",
      // subMenu: [],
      subMenu: [
        {
          display: "sideNavMenu.members",
          link: "/admin/enquiries/members",
          linkPage: false,
          onclick: null,
          urlEndWith: "members",
          className: "",
          permissionLevel: "Members",
          regularIcon: <PeopleCheckmark24Regular style={{ color: "black" }} />,
          filledIcon: <PeopleCheckmark24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "members",
        },
        {
          display: "sideNavMenu.myLooms",
          link: "/admin/enquiries/myLooms",
          linkPage: false,
          onclick: null,
          urlEndWith: "myLooms",
          className: "",
          permissionLevel: "My Looms",
          regularIcon: <DocumentData24Regular style={{ color: "black" }} />,
          filledIcon: <DocumentData24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "myLooms",
        },
        {
          display: "sideNavMenu.serviceRequest",
          link: "/admin/enquiries/serviceRequest",
          linkPage: false,
          onclick: null,
          urlEndWith: "serviceRequest",
          className: "",
          permissionLevel: "Service Request",
          regularIcon: <DocumentData24Regular style={{ color: "black" }} />,
          filledIcon: <DocumentData24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "serviceRequest",
        },
        {
          display: "sideNavMenu.reports",
          link: "/admin/enquiries/reports",
          linkPage: false,
          onclick: null,
          urlEndWith: "reports",
          className: "",
          permissionLevel: "Reports",
          regularIcon: <PeopleProhibited24Regular style={{ color: "black" }} />,
          filledIcon: <PeopleProhibited24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "reports",
        },
      ],
    },
    {
      display: "sideNavMenu.orders",
      link: "/admin/orders",
      linkPage: false,
      onclick: null,
      urlEndWith: "orders",
      className: "",
      permissionLevel: "Orders",
      // icon: <FaEdit style={{ color: "black" }} />,
      filledIcon: <ClipboardTaskListLtr24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <ClipboardTaskListLtr24Regular style={{ color: "black" }} />,
      eventKey: "orders",
      color: "white",
      // subMenu: [],
      subMenu: [
        {
          display: "sideNavMenu.services",
          link: "/admin/orders/services",
          linkPage: false,
          onclick: null,
          urlEndWith: "services",
          className: "",
          permissionLevel: "Services",
          regularIcon: <BoardSplit24Regular style={{ color: "black" }} />,
          filledIcon: <BoardSplit24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "services",
        },
      ],
    },
    {
      display: "sideNavMenu.post",
      link: "/admin/posts",
      linkPage: false,
      onclick: null,
      urlEndWith: "posts",
      className: "",
      permissionLevel: "Posts",
      // icon: <FaEdit style={{ color: "black" }} />,
      filledIcon: <CommentEdit24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <CommentEdit24Regular style={{ color: "black" }} />,
      eventKey: "posts",
      color: "white",
      // subMenu: [],
      subMenu: [
        {
          display: "sideNavMenu.infotainment",
          link: "/admin/posts/infotainment",
          linkPage: false,
          onclick: null,
          urlEndWith: "infotainment",
          className: "",
          permissionLevel: "Infotainment",
          regularIcon: <CardUi24Regular style={{ color: "black" }} />,
          filledIcon: <CardUi24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "infotainment",
        },
        {
          display: "sideNavMenu.requirement",
          link: "/admin/posts/requirement",
          linkPage: false,
          onclick: null,
          urlEndWith: "requirement",
          className: "",
          permissionLevel: "Requirement",
          regularIcon: <ClipboardTextLtr24Regular style={{ color: "black" }} />,
          filledIcon: <ClipboardTextLtr24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "requirement",
        },
        {
          display: "sideNavMenu.sales",
          link: "/admin/posts/sales",
          linkPage: false,
          onclick: null,
          urlEndWith: "sales",
          className: "",
          permissionLevel: "Sales",
          regularIcon: <ContentView24Regular style={{ color: "black" }} />,
          filledIcon: <ContentView24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "sales",
        },
      ],
    },
    {
      display: "sideNavMenu.category",
      link: "/admin/category",
      linkPage: false,
      onclick: null,
      urlEndWith: "category",
      className: "",
      permissionLevel: "Categories",
      filledIcon: <ShareAndroid24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <ShareAndroid24Regular style={{ color: "black" }} />,
      eventKey: "category",
      subMenu: [],
    },

    {
      display: "sideNavMenu.plans",
      link: "/admin/plans",
      linkPage: false,
      onclick: null,
      urlEndWith: "plans",
      className: "",
      permissionLevel: "Plans",
      // icon: <SubscriptionsIcon style={{ color: "black" }} />,
      filledIcon: <PeopleMoney24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <PeopleMoney24Regular style={{ color: "black" }} />,
      eventKey: "plans",
      subMenu: [],
    },
    {
      display: "sideNavMenu.recharge",
      link: "/admin/recharge",
      linkPage: false,
      onclick: null,
      urlEndWith: "recharge",
      className: "",
      permissionLevel: "Recharge",
      filledIcon: <PaymentIcon style={{ color: "#FF8D00" }} />,
      regularIcon: <PaymentIcon style={{ color: "black" }} />,
      eventKey: "recharge",
      subMenu: [],
    },
    {
      display: "Customer Service",
      link: "/admin/customer",
      linkPage: false,
      onclick: null,
      urlEndWith: "customer",
      className: "",
      permissionLevel: "Customer",
      filledIcon: <Headphones24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <Headphones24Regular style={{ color: "black" }} />,
      eventKey: "recharge",
      subMenu: [],
    },

    {
      display: "sideNavMenu.advertisement",
      link: "/admin/advertisement",
      linkPage: false,
      onclick: null,
      urlEndWith: "advertisement",
      className: "",
      permissionLevel: "Advertisement",
      regularIcon: <Megaphone24Regular style={{ color: "black" }} />,
      filledIcon: <Megaphone24Filled style={{ color: "#FF8D00" }} />,
      eventKey: "advertisement",
      subMenu: [],
    },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.security",
      link: "/admin/security/role",
      linkPage: false,
      onclick: null,
      urlEndWith: "role",
      className: "",
      permissionLevel: "Role",
      filledIcon: <PeopleCall24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <PeopleCall24Regular style={{ color: "black" }} />,
      eventKey: "security",
      subMenu: [
        {
          display: "sideNavMenu.role_function",
          link: "/admin/security/role",
          linkPage: false,
          onclick: null,
          urlEndWith: "role",
          className: "",
          permissionLevel: "Role",
          regularIcon: <PeopleCheckmark24Regular style={{ color: "black" }} />,
          filledIcon: <PeopleCheckmark24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "role",
        },
      ],
    },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.setting",
      link: "/admin/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      regularIcon: <Settings24Regular style={{ color: "black" }} />,
      filledIcon: <Settings24Filled style={{ color: "#FF8D00" }} />,
      eventKey: "setting",
      subMenu: [],
    },
  ];
}
export default SideBarMenu;
