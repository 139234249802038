import React from "react";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";

const useImageUpload = (setUserForm, userForm, handleClosePreview) => {
  const onUpload = async (e) => {
    const formdata = new FormData();
    const file = e.target.files[0];
    formdata.append("attachment", file);
    formdata.append("bucketName", "profile");

    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      formdata
    );

    if (response.data.responseCode === 109) {
      const uploadedUrl = response?.data?.attachmentUrl[0]; // Get URL only

      if (uploadedUrl) {
        setUserForm((prev) => ({
          ...prev,
          upload: [...(prev.upload || []), uploadedUrl], // Store URLs only
        }));
      }
    }
  };

  const deleteImages = (index) => {
    setUserForm((prev) => ({
      ...prev,
      upload: prev.upload.filter((_, i) => i !== index), // Remove by index
    }));
    handleClosePreview();
  };

  return { onUpload, deleteImages };
};

export default useImageUpload;
