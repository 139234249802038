import { Box, Container, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import TopBar from "./TopBar";
import ChatInput from "../../Enquiries/Members/ChartInput";
import LogMessage from "../../Enquiries/Members/LogMessages";
import LogMessages from "../../../../../utils/LogMessages";

export default function View({ complaintDetails, markAsResolve }) {
  console.log("complaintDetails", complaintDetails);
  const { listLogMessages, listLogs, createLogMessage } = LogMessages();
  useEffect(() => {
    if (complaintDetails?._id) {
      listLogMessages("customerService", complaintDetails?._id);
    }
  }, [complaintDetails?._id]);

  const { complaintTypeId, complaintId, optionsForReson, attachment } =
    complaintDetails;
  return (
    <Container style={{ width: "100%" }}>
      <Grid container style={{ margin: "4rem 0rem 1rem" }} spacing={2}>
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <TopBar rowDetails={complaintDetails} markAsResolve={markAsResolve} />
        </Grid>

        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <TextField
            label="Complaint Name"
            value={complaintTypeId?.complaintName}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            slotProps={{
              input: {
                readOnly: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Reason"
            multiline
            maxRows={4}
            value={complaintId?.reason}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            slotProps={{
              input: {
                readOnly: true,
              },
            }}
          />
          <Box
            style={{
              display: "flex",
              gap: "16px",
              marginTop: "16px",
              flexWrap: "wrap",
              // backgroundColor: "#F9F9F9",
            }}
          >
            {attachment && attachment?.length > 0 ? (
              attachment?.map((image, index) => (
                <Box
                  key={index}
                  style={{
                    position: "relative",
                    width: "120px",
                    height: "100px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={image.url}
                    alt={`Loom ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ))
            ) : (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  marginTop: "8px",
                  fontSize: "14px",
                }}
              >
                No additional images available.
              </Typography>
            )}
          </Box>
        </Grid>

        {/* <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
//for attachments
</Grid> */}
      </Grid>
      <hr />
      <br />
      {listLogs?.length > 0
        ? listLogs?.map((val, ind) => (
            <LogMessage
              message={val?.sendMessage}
              userName={val?.createdBy?.name}
              time={val?.createdAt}
              isShowLast={listLogs?.length - 1 === ind}
            />
          ))
        : "No logs present"}

      <ChatInput
        createLogMessage={createLogMessage}
        recordId={complaintDetails?._id}
        listLogMessages={listLogMessages}
        moduleType="customerService"
        createdFor={complaintDetails?.requestedBy?._id}
      />
    </Container>
  );
}
